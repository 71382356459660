import React, { useState, useRef } from "react"
import {
  Box,
  Grid,
  Text,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  Link
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DownloadIcon } from "@chakra-ui/icons"

import { useForm } from "react-hook-form"
import { VscWarning } from "react-icons/vsc";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  attachment: yup.mixed()
  .test("required", "Packet is Required", (value) => {
    return value && value.length;
  })
  .test("fileSize", "Max file size 5 MB", (value, context) => {
    return value && value[0] && value[0].size <= 5000000;
  }),
});

const CanadaBasedCarrierForm = (props) => {
 //   const [state, setState] = React.useState({})
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  })

  const errorStyles = {
    color: "#bf1650",
    fontSize: "1rem",
  };
  

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);

const formRef = useRef(null);

const SuccessMessage = () => (
      <Box my="12">      
          <Heading as="h4" w="100%" textAlign="center">Thank you for your submission.<br />
          We'll be in touch soon!</Heading>
      </Box>
  )

  const sendMailgunFormData = async (data) => {
    //event.preventDefault()
    setProcessing(true);

    if (!formRef.current) {
        console.log('something went wrong with the ref');
    }

    const formData = new FormData(formRef.current);


    // Use console.log() to see the data that is being sent to the server
    console.log(Array.from(formData.entries()));

   fetch('/.netlify/functions/send-checklist-of-documents', {
           method: 'POST',
           body: formData,
           // don't send headers due to boundary problem 
       })
       // Convering the response to JSON breaks status code
       .then(res => {
           console.log(res)
              if (res.status === 200) {
                    setSucceeded(true)
                    setProcessing(false)
                    reset()
                } else {
                    setSucceeded(false)
                    console.log("network error")
                    alert("Network error. Please try again later.")
                }
       }
    )
};


  return (
    <>
      <Box  px={4} mb={8}>
          <Link href={props.packetLink} target="_blank" rel="noopener noreferrer">
        <Button variant="gold-button" isFullWidth>
            Download Packet  <DownloadIcon />
        </Button>
        </Link>
        <Text my="4" fontWeight="bold">
            Fill in your packet and upload it here:
        </Text>
      { succeeded ? <SuccessMessage /> : (
        <>
        <form onSubmit={handleSubmit(sendMailgunFormData)} name="us-based-carrier-form" ref={formRef}>
          <input type="hidden" name="form_name" value="US Based Carrier Form" />
          <Box>
            <Box w="100%" my="4">
              <FormControl id="email">
                <FormLabel color="shadow.800">Your Name<Text color="red.200" as="span">*</Text></FormLabel>
                <Input type="text"
                    {...register("name", {required: true} )}
                />
              </FormControl>
              {errors.name && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.name?.message}</Box>
                  </HStack>
                )}
            </Box>
            <Box w="100%" my="4">
              <FormControl id="email">
                <FormLabel color="shadow.800">Email address<Text color="red.200" as="span">*</Text></FormLabel>
                <Input type="email" 
                   {...register("email", {required: true} )}
                   />
              </FormControl>
              {errors.email && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.email?.message}</Box>
                  </HStack>
                )}
            </Box>
          </Box>

            <Box my="4">
            <FormLabel>Upload Your Completed Packet<Text color="red.200" as="span">*</Text></FormLabel>
            <input
                    type="file"
                    id="attachment"
                    name="attachment"
                    {...register("attachment")}
                  />
               {errors.attachment && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.attachment?.message}</Box>
                  </HStack>
                )}
            </Box>

          <Button variant="gold-button" type="submit" mt={4} isFullWidth>
              {processing ? "Submitting..." : "Submit" }
          </Button>
        </form>
        </>
      )
}
      </Box>
    </>
  )
}

export default CanadaBasedCarrierForm
