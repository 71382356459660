import * as React from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import Layout from "../../components/layout"
import '../../components/layout.css'
import { 
  Box,
  Flex,
  Container,
  Heading,
  Text,
  Link,
  Button
} from "@chakra-ui/react"
import Seo from "../../Seo"
import { ChevronRightIcon } from "@chakra-ui/icons"
import SliceZone from '../../components/SliceZone/SliceZone'

import PageHero from "../../components/PageHero/PageHero"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../../utils/linkResolver"
import TransportationForm from "../../components/Transportation/UsBasedCarrierForm"
import IntraCanadianBrokerForm from "../../components/Transportation/IntraCanadianBrokerForm"

const UsBasedCarriersPage = ({ data }) => {

  const document = data.allPrismicPage.edges[0].node.data;

  return (
    <Layout>
      <Seo title="Canada Based Intra-Canadian Broker Checklist of Documents | Plateau Forest Products" />
      <Box>
      <Flex p={{md: '1rem', lg: '2rem', xl: '3rem'}}
                    flexDirection="column"
                    height="100%"
                    justifyContent="space-between"  
                >
                            <Box mb="8">
                                <Container maxW="container.xl">
                                    <Heading as="h2" color="leafy_green.500" fontSize={{base: '2rem', md: '4.5rem'}} textTransform="uppercase" my="8" textAlign="center" mb="12">
                                    Canada Based Intra-Canadian Broker Checklist of Documents
                                    </Heading>
                                    <Flex w="100%" flexWrap="wrap">
                                      <Box width="70%" pr="4">
                                        <Box textAlign='left'>
                                        <Text>Plateau Forest Products, LLC (PFP) requires new carriers to be qualified in advance in order to do business.  Accordingly, please follow the directions outlined below in order to become one of our qualified carriers:</Text>
                                        </Box>
                                        <Box>
                                          <ol>
                                            <li>Completed and Signed W-9 Tax Form<br />Please provide reason for submitting one or both tax forms.</li>
                                            <li>Valid Insurance Certificate sent directly from insurance agency with the following minimum coverages and requirements:</li>
                                          </ol>
                                        </Box>
                                        <Box>
                                          <table width="100%">
                                          <tr>
                                            <td>Commercial General Liability:</td>
                                            <td>$1,000,000 Minimum Coverage</td>
                                          </tr>
                                          <tr>
                                            <td>Additional Insured:</td>
                                            <td>Required</td>
                                          </tr>
                                          <tr>
                                            <td>Waiver of Subrogation:</td>
                                            <td>Required</td>
                                          </tr>
                                          <tr>
                                            <td>Auto Liability:</td>
                                            <td>$1,000,000 Minimum Coverage</td>
                                          </tr>
                                          <tr>
                                            <td>Additional Insured:</td>
                                            <td>Required</td>
                                          </tr>
                                          <tr>
                                            <td>Waiver of Subrogation:</td>
                                            <td>Required</td>
                                          </tr>
                                          <tr>
                                            <td>Cargo:</td>
                                            <td>$100,000 Minimum Coverage</td>
                                          </tr>
                                          <tr>
                                            <td>Cargo Deductible:</td>
                                            <td>$5,000 or less</td>
                                          </tr>
                                          <tr>
                                            <td>Certificate Holder:</td>
                                            <td>FOREST CITY TRADING GROUP, LLC &amp; ITS SUBSIDIARIES
                                              10250 S.W. GREENBURG RD, #300
                                              PORTLAND, OR 97223</td>
                                          </tr>
                                          <tr>
                                            <td>Workers Compensation coverage based on Province requirements: </td>
                                            <td>OR, If exempt, please provide a letter certifying the reason for exemption.</td>
                                          </tr>
                                          <tr>
                                            <td>Proof of insurance certificate sent directly from insurance agent:</td>
                                            <td>Required</td>
                                          </tr>
                                      </table>
                                        </Box>
    
                                        <Box my="4">
                                          <ol start="3">
                                            <li>Carrier Insurance Information Sheet</li>
                                            <li>Signed Shipper-Carrier Transportation Services Agreement</li>
                                            <li>Carrier Set-up Information Sheet</li>
                                          </ol>
                                          <Text>(Make sure THREE (3) references are listed and remit to information is correct.)</Text>
                                        </Box>
                                      </Box>
                                      <Box width="30%">
                                        <IntraCanadianBrokerForm 
                                          packetLink="https://prismic-io.s3.amazonaws.com/plateau-forest-products/cd94ead7-29b6-4dab-9e57-4617482fa8ae_Canadian-Broker-Packet_new.pdf"
                                        />
                                      </Box>
                                    </Flex>
                                </Container>
                            </Box>
                            
                        
                        <Box display={{base: 'none', md: 'block'}}>
                          
             </Box>
             </Flex>
      </Box>
        <Box>
          <SliceZone sliceZone={document.body}/>
        </Box>
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicPage(filter: {uid: {eq: "transportation"}}) {
      edges {
        node {
          _previewable
          data {
            page_title {
              text
            }
            page_subtitle {
              text
            }
            hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
            body {
              ... on PrismicPageDataBodyGreenCallToActionBlock {
                id
                slice_type
                primary {
                  block_color
                  yellow_title {
                    text
                  }
                  white_headline {
                    text
                  }
                  button_link {
                    uid
                  }
                  button_label {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyWhiteCallToActionBlock {
                id
                slice_type
                primary {
                  yellow_title {
                    text
                  }
                  white_headline {
                    text
                  }
                  button_link {
                    uid
                  }
                  button_label {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyWhatWeTradeBlock {
                id
                items {
                  short_description {
                    text
                  }
                  product_name {
                    text
                  }
                  product_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                    alt
                  }
                  button_link {
                    uid
                  }
                  button_label {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicPageDataBodyIndustryMembers {
                slice_type
                primary {
                  industry_partners {
                    document {
                      ... on PrismicIndustryPartners {
                        data {
                          industry_member {
                            industry_logo {
                              alt
                              copyright
                              url
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                }
                              }
                            }
                            industry_link {
                              url
                              target
                            }
                          }
                        }
                      }
                    }
                  }
                }
                id
              }
              ... on PrismicPageDataBodyContentArea {
                id
                slice_type
                primary {
                  white_or_green_block_
                  content_title {
                    text
                  }
                  content {
                    raw
                  }
                }
              }
              ... on PrismicPageDataBodyCompanyOverviewBlock {
                id
                slice_type
                primary {
                  description {
                    text
                  }
                  block_title {
                    text
                  }
                }
                items {
                  brief_success_statement {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyTestimonials {
                id
                slice_type
                primary {
                  title {
                    text
                  }
                }
                items {
                  testimonial_text {
                    text
                  }
                  person_name {
                    text
                  }
                  company_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`


export default withPrismicPreview(UsBasedCarriersPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])